var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.$store.state.metaShow,
          expression: "$store.state.metaShow",
        },
      ],
      staticClass: "login_box",
      attrs: { "element-loading-text": _vm.$store.state.loginText },
      on: {
        click: function ($event) {
          _vm.selectShow = false
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "login", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "login_left", attrs: { xs: 24, sm: 12 } },
            [_c("h2", [_vm._v("VisionMoney")])]
          ),
          _c(
            "el-col",
            { staticClass: "login_right", attrs: { xs: 24, sm: 12 } },
            [
              _c("div", { staticClass: "login_content" }, [
                _c("h2", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settingPassWordTips")) +
                      "\n        "
                  ),
                ]),
                _c("h3", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("updatePasswordTips")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.password.rules === 3,
                      borderY: _vm.Phonefrom.password.rules === 2,
                    },
                  },
                  [
                    (_vm.passwordEye ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord"),
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.Phonefrom.password.value)
                              ? _vm._i(_vm.Phonefrom.password.value, null) > -1
                              : _vm.Phonefrom.password.value,
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            change: function ($event) {
                              var $$a = _vm.Phonefrom.password.value,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password,
                                      "value",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password,
                                      "value",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.Phonefrom.password, "value", $$c)
                              }
                            },
                          },
                        })
                      : (_vm.passwordEye ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord"),
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(_vm.Phonefrom.password.value, null),
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.Phonefrom.password,
                                "value",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password.value,
                              expression: "Phonefrom.password.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord"),
                            type: _vm.passwordEye ? "text" : "password",
                          },
                          domProps: { value: _vm.Phonefrom.password.value },
                          on: {
                            focus: function ($event) {
                              return _vm.getfocus("password")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.Phonefrom.password,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    !_vm.passwordEye
                      ? _c("img", {
                          attrs: {
                            src: require("../../assets/images/list_icon_eye_1@2x.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.passwordEye = !_vm.passwordEye
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.passwordEye
                      ? _c("img", {
                          attrs: {
                            src: require("../../assets/images/list_icon_eye_2@2x.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.passwordEye = !_vm.passwordEye
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.password2.rules === 3,
                      borderY: _vm.Phonefrom.password2.rules === 2,
                    },
                  },
                  [
                    (_vm.password2Eye ? "text" : "password") === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord2"),
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.Phonefrom.password2.value
                            )
                              ? _vm._i(_vm.Phonefrom.password2.value, null) > -1
                              : _vm.Phonefrom.password2.value,
                          },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkpasswordShow()
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            change: function ($event) {
                              var $$a = _vm.Phonefrom.password2.value,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password2,
                                      "value",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.Phonefrom.password2,
                                      "value",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.Phonefrom.password2, "value", $$c)
                              }
                            },
                          },
                        })
                      : (_vm.password2Eye ? "text" : "password") === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord2"),
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.Phonefrom.password2.value,
                              null
                            ),
                          },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkpasswordShow()
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.Phonefrom.password2,
                                "value",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.Phonefrom.password2.value,
                              expression: "Phonefrom.password2.value",
                            },
                          ],
                          class: { red: _vm.Phonefrom.password2.rules === 3 },
                          attrs: {
                            placeholder: _vm.$t("settingnewPassWord2"),
                            type: _vm.password2Eye ? "text" : "password",
                          },
                          domProps: { value: _vm.Phonefrom.password2.value },
                          on: {
                            blur: function ($event) {
                              _vm.getBlur("password2")
                              _vm.checkpasswordShow()
                              _vm.checkPasswordLength()
                            },
                            focus: function ($event) {
                              return _vm.getfocus("password2")
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.Phonefrom.password2,
                                "value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    !_vm.password2Eye
                      ? _c("img", {
                          attrs: {
                            src: require("../../assets/images/list_icon_eye_1@2x.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.password2Eye = !_vm.password2Eye
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.password2Eye
                      ? _c("img", {
                          attrs: {
                            src: require("../../assets/images/list_icon_eye_2@2x.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.password2Eye = !_vm.password2Eye
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm.passwordShow
                  ? _c("h6", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("passwordError")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm.passwordLengthShow
                  ? _c("h6", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("passwordLenthTip")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _c("h4", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("passwordTips")) +
                      "\n        "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "input_box",
                    class: {
                      borderR: _vm.Phonefrom.passwordTips.rules === 3,
                      borderY: _vm.Phonefrom.passwordTips.rules === 2,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.Phonefrom.passwordTips.value,
                          expression: "Phonefrom.passwordTips.value",
                        },
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("passwordTips2"),
                      },
                      domProps: { value: _vm.Phonefrom.passwordTips.value },
                      on: {
                        focus: function ($event) {
                          return _vm.getfocus("passwordTips")
                        },
                        blur: function ($event) {
                          return _vm.getBlur("passwordTips")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.Phonefrom.passwordTips,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.subPasswordLoad,
                        expression: "subPasswordLoad",
                      },
                    ],
                    staticClass: "login_button2",
                    class: {
                      yz_button: _vm.confirmDisable && !_vm.passwordShow,
                    },
                    attrs: { disabled: !_vm.confirmDisable },
                    on: {
                      click: function ($event) {
                        return _vm.debounceLogin()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("Finished")) + "\n        "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }