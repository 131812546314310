<template>
  <div
    class="login_box"
    @click="selectShow = false"
    v-loading="$store.state.metaShow"
    :element-loading-text="$store.state.loginText"
  >
    <el-row type="flex" class="login">
      <el-col :xs="24" :sm="12" class="login_left">
        <h2>VisionMoney</h2>
      </el-col>
      <el-col :xs="24" :sm="12" class="login_right">
        <div class="login_content">
          <h2>
            <!-- 设置密码 -->
            {{ $t("settingPassWordTips") }}
          </h2>
          <h3>
            {{ $t("updatePasswordTips") }}
          </h3>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password.rules === 3,
              borderY: Phonefrom.password.rules === 2,
            }"
          >
            <input
              :type="passwordEye ? 'text' : 'password'"
              :placeholder="$t('settingnewPassWord')"
              v-model="Phonefrom.password.value"
              :class="{ red: Phonefrom.password.rules === 3 }"
              @focus="getfocus('password')"
            />
            <img
              v-if="!passwordEye"
              src="../../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
            <img
              v-if="passwordEye"
              src="../../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="passwordEye = !passwordEye"
            />
          </div>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.password2.rules === 3,
              borderY: Phonefrom.password2.rules === 2,
            }"
          >
            <input
              :type="password2Eye ? 'text' : 'password'"
              :placeholder="$t('settingnewPassWord2')"
              v-model="Phonefrom.password2.value"
              :class="{ red: Phonefrom.password2.rules === 3 }"
              @blur="
                getBlur('password2');
                checkpasswordShow();
                checkPasswordLength();
              "
              @focus="getfocus('password2')"
            />
            <img
              v-if="!password2Eye"
              src="../../assets/images/list_icon_eye_1@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
            <img
              v-if="password2Eye"
              src="../../assets/images/list_icon_eye_2@2x.png"
              alt=""
              @click="password2Eye = !password2Eye"
            />
          </div>
          <h6 v-if="passwordShow">
            <!-- 两次输入密码不相符 -->
            {{ $t("passwordError") }}
          </h6>
          <h6 v-if="passwordLengthShow">
            <!-- 密码长度不够提示 -->
            {{ $t("passwordLenthTip") }}
          </h6>
          <h4>
            <!-- 密码提示 -->
            {{ $t("passwordTips") }}
          </h4>
          <div
            class="input_box"
            :class="{
              borderR: Phonefrom.passwordTips.rules === 3,
              borderY: Phonefrom.passwordTips.rules === 2,
            }"
          >
            <input
              type="text"
              :placeholder="$t('passwordTips2')"
              v-model="Phonefrom.passwordTips.value"
              @focus="getfocus('passwordTips')"
              @blur="getBlur('passwordTips')"
            />
          </div>
          <button
            class="login_button2"
            @click="debounceLogin()"
            :class="{
              yz_button: confirmDisable && !passwordShow,
            }"
            :disabled="!confirmDisable"
            v-loading="subPasswordLoad"
          >
            <!-- 注册 -->
            {{ $t("Finished") }}
          </button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import countries from "@/common/js/countryList";
import { loginVerification, logincheck } from "@/api/api";
import { setPassword, updatePassword, updateProject } from "../../api/api";
import CryptoJS from "crypto-js";
// import axios from 'axios'
// import CryptoJS from 'crypto-js'
export default {
  data() {
    return {
      passwordLengthShow: false,
      passwordEye: false,
      password2Eye: false,
      passwordShow: false,
      subPasswordLoad: false,
      localuserData: JSON.parse(localStorage.getItem("localuserData")),
      Phonefrom: {
        originPassword: {
          rules: 1,
          value: "",
        },

        password: {
          rules: 1,
          value: "",
        },
        password2: {
          rules: 1,
          value: "",
        },
        passwordTips: {
          rules: 1,
          value: "",
        },
      },
    };
  },
  computed: {
    tip1() {
      return this.$t("codeTips");
    },
    // passwordShow() {
    //   if (this.Phonefrom.password2.value) {
    //     return this.Phonefrom.password.value !== this.Phonefrom.password2.value;
    //   } else {
    //     return false;
    //   }
    // },
    confirmDisable() {
      return (
        this.Phonefrom.password.value !== "" &&
        this.Phonefrom.password2.value !== "" &&
        this.Phonefrom.passwordTips.value !== ""
      );
    },
  },
  watch: {
    // "$store.state.metaShow": function () {
    //   // console.log(this.$store)
    //   let userMeta = this.$store.state.userMeta;
    //   console.log(userMeta);
    //   if (!this.$store.state.metaShow) {
    //     // console.log()
    //     if (userMeta.showId && userMeta.infoTxId && userMeta.name) {
    //       if (this.$route.query.from) {
    //         window.location.href = this.$route.query.from + "?type=registered";
    //       } else {
    //         setTimeout(() => {
    //           this.gotoUrl("index");
    //         }, 1000);
    //       }
    //     } else {
    //       return this.$message.error(this.$t("metaTxt"));
    //     }
    //   }
    // },
  },
  methods: {
    checkpasswordShow() {
      if (this.Phonefrom.password.value == this.Phonefrom.password2.value) {
        this.passwordShow = false;
      } else {
        this.passwordShow = true;
      }
    },
    checkPasswordLength() {
      if (this.Phonefrom.password2.value && this.Phonefrom.password.value) {
        if (this.Phonefrom.password2.value !== this.Phonefrom.password.value) {
          this.passwordShow = true;
        } else {
          this.passwordShow = false;
        }
      }
      if (
        (this.Phonefrom.password.value &&
          this.Phonefrom.password.value.length < 6) ||
        this.Phonefrom.password.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        (this.Phonefrom.password2.value &&
          this.Phonefrom.password2.value.length < 6) ||
        this.Phonefrom.password2.value.length > 32
      ) {
        this.passwordLengthShow = true;
      }
      if (
        this.Phonefrom.password.value &&
        this.Phonefrom.password.value.length >= 6 &&
        this.Phonefrom.password.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      if (
        this.Phonefrom.password2.value &&
        this.Phonefrom.password2.value.length >= 6 &&
        this.Phonefrom.password2.value.length <= 32
      ) {
        this.passwordLengthShow = false;
      }
      return this.passwordLengthShow && !this.passwordShow;
    },
    getfocus(type) {
      this[type] = 2;
    },
    getBlur(type) {
      if (!this[type] && type !== "") {
        this[type + "Show"] = 3;
      } else {
        this[type + "Show"] = 1;
      }
    },

    debounceLogin() {
      if (this.checkPasswordLength()) {
        return false;
      }
      this.debounce(this.setNewPassword, 1000, true);
    },
    setNewPassword() {
      if (this.subPasswordLoad || this.passwordShow) {
        console.log(
          "setNewPassword err:",
          this.subPasswordLoad,
          this.passwordShow
        );
        return false;
      }
      let encryptPassword = "";
      try {
        this.subPasswordLoad = true;
        encryptPassword = this.$utils.encryptPassword(
          this.Phonefrom.password.value
        );
      } catch (error) {
        return (this.subPasswordLoad = false);
      }
      if (!encryptPassword) {
        console.log("encryptPassword err:", encryptPassword);
        return false;
      }
      const _password = this.$route.params.password;
      if (!_password) {
        console.log("Password err:", _password);
        return false;
      }
      const key = this.$ShowMoney.noneMnemonic.createMasterHdPrivateKey(
        this.$store.state.userData.register == "email"
          ? this.$store.state.userData.email
          : this.$store.state.userData.phone,
        _password,
        this.$route.params.pk2 || this.$store.state.userData.pk2,
        this.$route.params.tag || this.$store.state.userData.tag
      );
      const de_mnemonic = this.$ShowMoney.noneMnemonic.decodeMnemonic(
        key.mnemonic,
        key.ppk
      );
      console.log("解码后的助记词：", de_mnemonic);
      const encrypt_mnemonic = this.$utils.aesEncryptMem(
        de_mnemonic,
        this.Phonefrom.password.value
      );
      let address = "";
      try {
        address = window.ShowMoney.bsv.Mnemonic.fromString(de_mnemonic)
          .toHDPrivateKey()
          .deriveChild(
            this.$store.state.userData.tag == "new"
              ? "m/44'/10001'/0'"
              : "m/44'/145'/0'"
          )
          .deriveChild(0)
          .deriveChild(0)
          .privateKey.toAddress(
            this.$store.state.isTestnet
              ? window.ShowMoney.bsv.Networks.testnet.name
              : window.ShowMoney.bsv.Networks.mainnet.name
          )
          .toString();
      } catch (error) {
        this.$message.error(this.$t("Mnemonic_error"));
        console.log("Mnemonic.fromString", error);
        this.subPasswordLoad = false;
        return;
      }
      let re =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
      let userType = this.$store.state.userData.register;
      setPassword({
        userType: userType,
        phone: userType == "phone" ? this.$store.state.userData.phone : "",
        email: userType == "email" ? this.$store.state.userData.email : "",
        password: encryptPassword,
        affirmPassword: encryptPassword,
        enCryptedMnemonic: encrypt_mnemonic,
        remark: this.Phonefrom.passwordTips.value,
        address: address,
      })
        .then(() => {
          this.$store.dispatch("logout");
          this.$router.replace({
            name: "login",
          });
          this.$message.success(this.$t("passwordUpdated"));
          location.reload();
          this.subPasswordLoad = false;
        })
        .catch((err) => {
          this.$message.error("setPassword 请求出错");
        });
    },
  },
  destroyed() {
    // clearInterval(this.timeC);
  },
  created() {
    // this.yzmText = this.tip1
    console.log(this.yzmText);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/intl.css";
.login_box {
  background: url("../../assets/images/home_bg.png");
  width: 100vw;
  background-size: cover;
  background-position: 50%;
  min-height: calc(100vh - 68px);
}
.login {
  max-width: 1080px;
  margin: 0 auto;
  padding-top: 7vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.login_left {
  padding-left: 20px;
  h2 {
    margin-top: 22px;
    font-size: 45px;
    color: #fff;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 25px;
    color: #bfc2cc;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    i {
      width: 8px;
      height: 8px;
      background: rgba(234, 179, 0, 1);
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .app_downloads {
    display: flex;
    margin-top: 45px;
    a {
      width: 195px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.08);
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 30px;
      margin-right: 22px;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
    }
  }
}
.login_right {
  padding: 20px;
}
.login_content {
  max-width: 390px;
  background: #fff;
  margin: 0 auto;
  border-radius: 17px;
  padding: 50px;
  .sign {
    display: flex;
    justify-content: space-between;
  }
  h6 {
    margin: 5px 0;
    font-size: 12px;
    color: #f56c6c;
  }
  h4 {
    margin-top: 20px;
    font-size: 16px;
  }
  p {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #303133;
  }
  h2 {
    color: #303133;
    font-size: 26px;
    margin-bottom: 2px;
    cursor: pointer;
    font-weight: normal;
    font-family: PingFang SC;
  }
  .blod {
    font-weight: bold;
  }
  .countries {
    height: 60px;
    font-size: 15px;
    line-height: 60px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #bfc2cc;
    .phoneCode {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .select_phone {
      position: absolute;
      height: 300px;
      left: 0;
      top: 95%;
      z-index: 1;
      background: #fff;
      width: 100%;
      overflow: auto;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #bfc2cc;
    }
    .select_phone::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 10px;
    }
    .select_phone::-webkit-scrollbar-track {
      box-shadow: none;
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .select_phone::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      background-color: #aaa;
    }
    .item_phone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      .item_name {
        width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.input_box {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #bfc2cc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .yzmShow {
    background: #eab300;
  }
  img {
    width: 25px;
    height: 25px;
  }
  .red {
    &::-webkit-input-placeholder {
      color: #f56c6c;
    }
    &:-moz-placeholder {
      color: #f56c6c;
    }
    &::-moz-placeholder {
      color: #f56c6c;
    }
    &:-ms-input-placeholder {
      color: #f56c6c;
    }
  }
  input {
    height: 59px;
    font-size: 15px;
    color: #303133;
    border: none;
    display: block;
    outline: none;
    width: 100%;
  }
  .yzm {
    width: 60%;
  }
  button {
    font-size: 12px;
    color: #f9f9f9;
    width: 96px;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    background: rgba(191, 194, 204, 1);
    border-radius: 5px;
  }
}
.borderY {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #eab300;
}
.borderR {
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid #f56c6c;
}
.type_button {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #606266;
    font-size: 15px;
  }
}
.login_button {
  width: 270px;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
.login_button2 {
  width: 270px;
  height: 60px;
  cursor: pointer;
  background: rgba(191, 194, 204, 1);
  border-radius: 12px;
  color: #fff;
  font-size: 24px;
  line-height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}
.yz_button {
  background: #eab300;
}
::v-deep .el-loading-spinner .path {
  stroke: #eab300;
}
::v-deep .el-loading-mask {
  background-color: rgba(255, 255, 255, 0.5);
}
.user_home {
  ::v-deep .el-dialog {
    max-width: 600px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .login_left {
    height: calc(100vh - 80px);
  }
  .login_right {
    padding-bottom: 60px;
  }
  .login_button {
    margin-top: 45px;
  }
  .login_left .app_downloads a {
    font-size: 12px;
  }
  .user_home {
    ::v-deep .el-dialog {
      border-radius: 15px;
      width: 80%;
    }
  }
}
</style>
